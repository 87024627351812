import { Colours } from "@pasabi/ui-components";
import { IListResponse } from "@pasabi/ui-patterns";
import { AxiosError } from "axios";
import { RawLocation } from "vue-router";
import { Module as Mod } from "vuex";
import { APIError } from "../api/apierror";

export enum QueryTypes {
  Query_PositiveBiasCrossIPCombo = "positiveBiasCrossIPCombo",
  Query_PositiveBiasCrossIPComboDemo = "positiveBiasCrossIPComboDemo",
  Query_SecondaryQuery = "secondaryQuery",
  Query_PositiveBiasLocalIPCAT = "positiveBiasLocalIPCAT",
  Query_FlaggedDataCAT = "catFlaggedData",
  Query_BehaviourAnalysis = "behaviourAnalysis",
  Query_Spam = "spam",
  Query_Scam = "scam",
  Query_NegativeBiasCrossIPCombo = "negativeBiasCrossIPCombo",
}

export enum EventStatus {
  UNKNOWN = 0,
  FILTERED = 1,
  ACTIVE = 2,
  PENDING = 3,
}

export enum ActionStatus {
  NO_ACTION = 0,
  NEEDS_INVESTIGATION = 1,
  ACTION = 2,
}

export interface Run {
  runId: string;
  query: QueryTypes;
  runStartDate: Date;
  runEndDate: Date;
  dateCreated: Date;
  runName: string;
}

export interface APIRun {
  display_id: string;
  query: QueryTypes;
  start_date: string;
  end_date: string;
  created_date: string;
}
export interface Event {
  display_id: string;
  external_id: string;
  title: string;
  text: string;
  status: EventStatus;
  subject_display_id: string;
  subject_external_id: string;
  subject_name: string;
  created_date: Date;
  actor_display_id: string;
  actor_external_id: string;
  actor_name: string;
  email_address: string;
  email_disposable: boolean;
  invitation_method: string;
  ip_display_id: string;
  ip_address: string;
  spam: boolean;
  isp_display_id: string;
  isp_name: string;
  primary_detected_lang: string;
  report_reason: string;
  close_matches?: string[];
  close_matches_count?: number;
  exact_matches?: string[];
  exact_matches_count?: number;
  score?: number;
  score_percentage?: number;
  labels?: string[];
  profanity?: boolean;
  profanity_score?: number;
  match_email?: boolean;
  match_phone?: boolean;
}

export interface Actor {
  display_id: string;
  external_id: string;
  name: string;
  username: string;
  email_address: string;
  action: boolean;
  events_score_percentage: number;
  actor_score_percentage: number;
  contains_spam: boolean;
  activated_date: string;
  geoc_count: number;
  countries_count: number;
  cities_count: number;
  score: number;
  similar_email_count: number;
  email_score: number;
  email_recent_abuse?: boolean;
  email_disposable?: boolean;
  email_valid?: boolean;
  labels?: string[];
  event_labels?: string[];
  tags: string[];
}

export type ActorOverview = {
  actors: Actor[];
  details: Actor;
  events: Event[];
  eventsHistory: IDashboardTimeSeriesData[];
  ips: IP[];
  isps: ISP[];
  phoneNumbers: PhoneNumber[];
  scores: IDashboardTimeSeriesData[];
  subjects: Subject[];
};

export interface ActorGraph extends Actor {
  event_count: number;
  ip_count: number;
}

export interface Subject {
  display_id: string;
  external_id: string;
  name: string;
  tier: string;
  action: ActionStatus;
  events_over_threshold_percentage: number;
  actors_over_threshold_percentage: number;
  score: number;
  tags: string[];
}

export interface SubjectGraph extends Subject {
  events_over_threshold: number;
  ip_count: number;
  actors_over_threshold: number;
}

export interface SubjectOverview {
  details: Subject;
  scores: IDashboardTimeSeriesData[];
  actors: Actor[];
  events: Event[];
  ips: IP[];
  isps: ISP[];
  subjects: Subject[];
  eventsSummary: IDashboardTimeSeriesData[];
  eventsHistory: IDashboardTimeSeriesData[];
}

export interface Filter {
  key: string;
  values: Array<string | number | boolean>;
}

export interface IP {
  display_id: string;
  address: string;
  isp_display_id: string;
  isp_name?: string;
  country_code?: string;
  city?: string;
  connection_type?: string;
  score?: number;
  region?: string;
  vpn?: boolean;
  proxy?: boolean;
  asn?: string;
  events_count: number;
  actors_count: number;
  subjects_count: number;
}

export interface IPOverview {
  events_history: IDashboardTimeSeriesData[];
}

export interface ISP {
  display_id: string;
  name: string;
  events_count: number;
  ips_count: number;
  actors_count: number;
  subjects_count: number;
}

export interface ISPOverview {
  countries_breakdown: IDashboardTimeSeriesData[];
}

export interface SubjectISPsAggregate extends ISP {
  isp: string;
  count: number;
  ips: string[];
}

export interface PhoneNumber {
  number: string;
  active: boolean;
  valid: boolean;
}

export interface Pagination {
  page: number;
  size: number;
  start: number;
  end: number;
}

export interface SortValue {
  key: string;
  descending: boolean;
}

export interface FilterUpdate {
  key: string;
  value: string | string[];
}

export interface FilterValues {
  [key: string]: string[] | string;
}

export interface PagedSortCriteria {
  page: number;
  itemsPerPage: number;
  sort?: SortValue;
  filters?: FilterValues;
}

export enum RequestState {
  Idle = 1,
  Loading,
  Error,
  Done,
}

export type ResponseError = AxiosError | APIError | Error | null;

export type BusinessBatchRequestParams = { businessId: string; batchId: string };

export type SimpleBatchRequestParams = { batchId: string };

export type BusinessRequestParams = { businessId: string };

export type CommentTagRequestParams = { contentType: CommentTagType; contentId: string };

export type AddCommentRequestParams = {
  contentType: CommentTagType;
  contentId: string;
  text: string;
};

export type TagsRequestParams = { contentType: CommentTagType };

export type UpdateTagsRequestParams = {
  contentType: CommentTagType;
  contentId: string;
  tags: string[];
};

export type ProfileBatchRequestParams = { profileId: string; batchId: string };

export interface HeadlineStat {
  type: string;
  value: number;
  count: number;
  change?: number;
}

export interface KeyCount {
  key: string;
  count: number;
}

export interface KeyCountTimeSeries {
  date: string;
  entries: KeyCount[];
}

export interface KeyCountTimeSeriesWithKeys {
  keys: string[];
  data: KeyCountTimeSeries[];
}

export interface DashboardBusiness {
  _id: string;
  action: boolean;
  actionNew: string;
  profilesPercentage: number;
  reviewsPercentage: number;
  business_name: string;
  business_id: string;
}

export interface DashboardProfile {
  shouldAction: boolean;
  username: string;
  profile_id: string;
  email: string;
  count: number;
}

export interface DashboardReview {
  business_name: string;
  reviewId: string;
  email: string;
  status: string;
  totalReviewScore: number;
  totalReviewScorePercentage: number;
  title: string;
  text: string;
  isReviewSpam: boolean;
}

// this is a type alias to allow subclassing vuex-decorator-modules, I don't think I can fix
// the generic type so it can be used from any child so setting to any
// eslint-disable-next-line
export type ParentModuleType = Mod<ThisType<any>, any>;

export interface User {
  _id: string;
  // forename: string;
  // surname: string;
  fullname: string;
}

export interface CommentData {
  _id: string;
  id: string;
  date_created: Date;
  date_modified: Date;
  author: User;
  text: string;
}

export enum CommentTagType {
  business = "business",
  profile = "profile",
}

export interface FilterData {
  alias?: string;
  key: string;
  multiple: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any[];
}

export enum OverviewDetailsComponents {
  span = "span",
  UiPercentCircle = "ui-percent-circle",
}

export interface OverviewDetails {
  label: string;
  component?: OverviewDetailsComponents;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: Record<string, any>;
  content?: string | number;
  linkTo?: RawLocation;
}
export interface IDashboardSummaryData {
  count: number;
  total?: number;
  run?: APIRun;
  previous?: {
    count: number;
    change: number;
    run: APIRun;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IDashboardListData = IListResponse<any>["data"]["payload"];

export interface IDashboardGroupCounterData {
  key: string;
  count: number;
}

export type IDashboardTimeSeriesData = {
  key: string;
  count: number;
} & {
  [otherCount: string]: number;
};

export interface IDashboardBaseLayout {
  label?: string;
  help?: string;
  icon?: string;
  rows?: number;
  cols?: number;
  highlight?: (typeof Colours)[number];
  glow?: (typeof Colours)[number];
}

export interface IDashboardSummaryLayout extends IDashboardBaseLayout {
  type: "number" | "time-diff";
}

export interface IDashboardGroupCounterLayout extends IDashboardBaseLayout {
  type: "pie" | "bar";
  style?: string;
}

export interface IDashboardPieLayout extends IDashboardGroupCounterLayout {
  type: "pie";
  style: "flat" | "flower";
}

export interface IDashboardBarLayout extends IDashboardGroupCounterLayout {
  type: "bar";
  style: "vertical" | "horizontal";
}

export interface IDashboardListLayout extends IDashboardBaseLayout {
  type: "actors" | "actors_geoc" | "actors_similar_emails" | "subjects" | "ips" | "isps" | "events";
}

export interface IDashboard {
  display_id: string;
  label: string;
  metrics: IDashboardMetric[];
  layout?: { cols?: number };
}

export interface IDashboardBaseMetric {
  key: string;
  id?: string;
  type: "summary" | "timeSeries" | "groupCounter" | "list";
  layout?: IDashboardBaseLayout;
}

export interface IDashboardSummaryMetric extends IDashboardBaseMetric {
  type: "summary";
  layout?: IDashboardSummaryLayout;
}

export interface IDashboardTimeSeriesMetric extends IDashboardBaseMetric {
  type: "timeSeries";
}

export interface IDashboardGroupCounterMetric extends IDashboardBaseMetric {
  type: "groupCounter";
  layout?: IDashboardPieLayout | IDashboardBarLayout;
}

export interface IDashboardListMetric extends IDashboardBaseMetric {
  type: "list";
  layout?: IDashboardListLayout;
}

export type IDashboardMetric =
  | IDashboardSummaryMetric
  | IDashboardTimeSeriesMetric
  | IDashboardGroupCounterMetric
  | IDashboardListMetric;
